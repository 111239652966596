import React, { HTMLAttributes, useEffect, useMemo, useRef } from 'react'

import { MenuItem, NavbarProps } from './Navbar.types'
import { NavbarRoot } from './Navbar.styles'
import { buildClassesWithDefault } from '../../utils/Helper'
import { getMenuItemsByRenderedSections } from './Navbar.utils'
import { useState } from 'react'
import GoogleTranslate from '../../components/google-translate/GoogleTranslate'
import Link from 'next/link'
import NavbarMenu from './NavbarMenu'
import NavbarMobileMenu from './NavbarMobileMenu'
import NavbarOpenMobileMenuButton from './NavbarOpenMobileMenuButton'
import useWindowSize from '../../hooks/useWindowSize'

const Navbar = ({
    showLogo,
    logoLocationHref = window.location.origin,
    isLogoFromApi,
    components,
    logo,
    reviewsRating,
    reviewsCount,
    reviewsRatingUrl,
    ...props
}: NavbarProps) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
    const [isScrolled, setIsScrolled] = useState<boolean>(false)
    const menuRef = useRef<HTMLDivElement>()
    const [menuWidth, setMenuWidth] = useState<number>(menuRef?.current?.offsetWidth)
    const { width: windowSizeWidth } = useWindowSize()
    const [navbarMenuVisibilityClassName, setNavbarMenuVisibilityClassName] = useState<string>('absolute opacity-0')

    useEffect(() => {
        setTimeout(() => {
            setNavbarMenuVisibilityClassName('hidden')
        }, 50)
    }, [])

    useEffect(() => {
        const onScroll = () => {
            setIsScrolled(window.scrollY > 10)
        }

        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        setMenuWidth(menuRef?.current?.offsetWidth)
    }, [])

    useEffect(() => {
        if (isMobileMenuOpen) {
            // INFO:
            // if current styling is *hidden* then change to visible, otherwise change to hidden
            document.body.style.overflowY = document.body.style.overflowY === 'hidden' ? 'visible' : 'hidden'
        } else {
            document.body.style.overflowY = 'visible'
        }
    }, [isMobileMenuOpen])

    const showLaptopMenu = useMemo<boolean>(() => {
        if (!menuWidth) {
            return false
        }
        const layoutPadding = 48
        const logoWidth = 240
        return windowSizeWidth - menuWidth - 3 * layoutPadding - logoWidth > 0
    }, [menuRef?.current, windowSizeWidth])

    useEffect(() => {
        if (showLaptopMenu && isMobileMenuOpen) {
            setIsMobileMenuOpen(false)
        }
    }, [showLaptopMenu, isMobileMenuOpen])

    const menuItemsByRenderedSections = useMemo<MenuItem[]>(() => {
        return getMenuItemsByRenderedSections(components)
    }, [components])

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prevState: boolean) => {
            return !prevState
        })
    }

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false)
    }

    const renderLogo = () => {
        const canShowLogo = showLogo && typeof window !== 'undefined'
        if (canShowLogo) {
            return (
                <div className={buildClassesWithDefault('flex items-center')}>
                    <Link href={logoLocationHref} target='_blank'>
                        {logo}
                    </Link>
                </div>
            )
        }
    }

    const renderLaptop = () => {
        if (menuItemsByRenderedSections?.length) {
            const navbarMenuClasses = {
                flex: showLaptopMenu,
                [navbarMenuVisibilityClassName]: !showLaptopMenu,
                'h-16': isLogoFromApi
            }
            const openMobileMenuButtonClasses = {
                flex: !showLaptopMenu,
                absolute: showLaptopMenu,
                'opacity-0': showLaptopMenu
            }
            return (
                <>
                    <div className={buildClassesWithDefault(navbarMenuClasses, '')}>
                        <NavbarMenu ref={menuRef} items={menuItemsByRenderedSections} onClose={closeMobileMenu} />
                    </div>
                    <div className={buildClassesWithDefault(openMobileMenuButtonClasses, '-mr-2')}>
                        <NavbarOpenMobileMenuButton onClick={toggleMobileMenu} />
                    </div>
                </>
            )
        }
    }

    const renderMobile = () => {
        if (menuItemsByRenderedSections?.length) {
            return (
                <NavbarMobileMenu
                    items={menuItemsByRenderedSections}
                    isOpen={isMobileMenuOpen}
                    onClose={closeMobileMenu}
                />
            )
        }
    }

    const showLanguagePicker = useMemo<boolean>(() => {
        return !isScrolled
    }, [isScrolled])

    return (
        <>
            <GoogleTranslate
                googleStars={reviewsRating}
                reviewsCount={reviewsCount}
                reviewsRatingUrl={reviewsRatingUrl}
            />
            <NavbarRoot
                {...(props as HTMLAttributes<HTMLElement>)}
                $isScrolled={isScrolled}
                className={showLanguagePicker ? 'mt-12' : ''}
            >
                <div className={buildClassesWithDefault('flex h-full items-center justify-between')}>
                    {renderLogo()}
                    {renderLaptop()}
                </div>
                {renderMobile()}
            </NavbarRoot>
        </>
    )
}

export default Navbar
