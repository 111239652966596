import { GoogleIcon } from '../icon/GoogleIcon'
import { GoogleTranslateProps } from './GoogleTranslate.types'
import GoogleLogo from './GoogleLogo'
import React, { useEffect, useRef } from 'react'
import Stars from '../stars/Stars'
import Typography from '../typography/Typography'

// Extend the Window interface to include google and googleTranslateElementInit properties
declare global {
    interface Window {
        google?: {
            translate: {
                TranslateElement: new (
                    options: {
                        pageLanguage: string
                        includedLanguages: string
                        autoDisplay: boolean
                    },
                    googleWidgetElementId: string
                ) => void
            }
        }
        googleTranslateElementInit?: () => void
    }
}

/**
 * @see https://www.w3schools.com/howto/howto_google_translate.asp Google Translate how it works
 * @see https://stackoverflow.com/a/64095746 React component for Google Translate
 */
const GoogleTranslate = ({ googleStars, reviewsCount, reviewsRatingUrl }: GoogleTranslateProps) => {
    const googleWidgetElementId = 'google_translate_element'
    const scriptRef = useRef<HTMLScriptElement | null>()

    const googleTranslateElementInit = () => {
        if (window.google && window.google.translate) {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'cs',
                    includedLanguages: 'cs,en,de,fr,es,ru,uk,nl',
                    autoDisplay: true
                },
                googleWidgetElementId
            )
        }
    }

    useEffect(() => {
        window.googleTranslateElementInit = googleTranslateElementInit

        // Check if the script already exists to avoid adding it multiple times
        if (!scriptRef.current) {
            const script = document.createElement('script')
            script.src = `//translate.google.com/translate_a/element.js?cb=${googleTranslateElementInit.name}`
            scriptRef.current = script
            document.body.appendChild(script)
        }

        return () => {
            // Clean up the script and remove the global function reference
            if (scriptRef.current) {
                document.body.removeChild(scriptRef.current)
                scriptRef.current = null
            }
            delete window.googleTranslateElementInit
        }
    }, [])

    const renderGoogleLogoAndReviews = () => {
        if (!googleStars && !reviewsCount) {
            return
        }
        return (
            <a href={reviewsRatingUrl} className='hidden md:flex flex-row items-center'>
                <GoogleIcon />
                <div className='flex items-center ml-2'>
                    <Typography className='text-secondaryBlue-50' size='small' type='semibold'>
                        {googleStars?.toFixed(1)}
                    </Typography>

                    <Stars color='gold' value={googleStars} className='px-1.5' />
                    <span className='text-xs text-grey-200'>{`${reviewsCount} recenzí`}</span>
                </div>
            </a>
        )
    }

    return (
        <div className='fixed left-0 right-0 top-0 h-12 bg-background-cta px-6 2xl:px-12 flex items-center justify-between sm:justify-end'>
            <div className='flex items-center text-text-cta gap-3.5 w-full justify-between'>
                <div>{renderGoogleLogoAndReviews()}</div>
                <div className='flex items-center gap-3'>
                    <span className='hidden md:block text-xs'>Přeložit stránku? Použijte Google Translate!</span>
                    <div id={googleWidgetElementId}></div>
                    <div className='text-xs flex items-end gap-1'>
                        <span className='whitespace-nowrap'>Powered by</span>
                        <div className='shrink-0'>
                            <GoogleLogo />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleTranslate
