import { FooterLogoItem, FooterRemaxLogo } from './Footer.styles'
import { FooterLogoListProps } from './Footer.types'
import BalloonLogo from '../logo/BalloonLogo'
import Column from '../grid/column/Column'
import FooterDactylGroup from './FooterDactylGroup'
import GoogleReviews from '../google-reviews/GoogleReviews'
import OfficeOfTheYearLogo from '../logo/OfficeOfTheYearLogo'
import React from 'react'
import Row from '../grid/row/Row'

const FooterLogoList = ({
    companyName,
    showCertificate,
    reviewsRating,
    reviewsRatingUrl,
    googleStars
}: FooterLogoListProps) => {
    const onGoogleReviewsClick = () => {
        window.location.href = reviewsRatingUrl
    }

    return (
        <Row className='pt-20 pb-10 !justify-center lg:!justify-between lg:!items-end'>
            <Column cols={12} lg='auto'>
                <FooterLogoItem>
                    <FooterRemaxLogo>
                        <BalloonLogo />
                        <h3 className='text-basic-white'>© 2024 {companyName}</h3>
                    </FooterRemaxLogo>
                </FooterLogoItem>
            </Column>
            {showCertificate && (
                <Column cols={12} lg='auto'>
                    <FooterLogoItem>
                        <OfficeOfTheYearLogo />
                    </FooterLogoItem>
                </Column>
            )}
            {reviewsRating !== null && (
                <Column cols={12} lg='auto'>
                    <FooterLogoItem className={reviewsRatingUrl ? 'cursor-pointer' : ''} onClick={onGoogleReviewsClick}>
                        <GoogleReviews stars={googleStars} />
                    </FooterLogoItem>
                </Column>
            )}
            <Column cols={12} lg='auto'>
                <FooterLogoItem>
                    <FooterDactylGroup />
                </FooterLogoItem>
            </Column>
        </Row>
    )
}

export default FooterLogoList
